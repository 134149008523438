import * as Sentry from '@sentry/sveltekit';
import { SENTRY_DSN } from './constants';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: import.meta.env.MODE
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
