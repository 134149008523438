import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [3];

export const dictionary = {
		"/": [~5],
		"/documentation": [13],
		"/health": [14],
		"/(dashboard)/keys": [11,[3],[4]],
		"/(auth)/login": [6,[2]],
		"/(auth)/logout": [~7,[2]],
		"/(auth)/reset-password": [8,[2]],
		"/(auth)/signup": [9,[2]],
		"/(auth)/verify-email": [~10,[2]],
		"/(dashboard)/webhooks": [12,[3],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';